import {UiBaseMainApp} from "ui-base/src/_resources/ts";

class TotalAVMainApp extends UiBaseMainApp
{
  protected getHandlers(): Array<any>
  {
    return super.getHandlers().concat([]);
  }

  protected getComponents(): Array<any>
  {
    return super.getComponents().concat([]);
  }
}

let app = new TotalAVMainApp();
